<!-- =========================================================================================
    File Name: TarifsReport.vue
    Description: Формирование отчета по тарифам за выбранный период
========================================================================================== -->


<template>
    <vx-card title="Отчет по тарифам за выбранный период">
        <div class="vx-row">
            <div class="vx-col w-full lg:w-1/3 mb-base">

                <div class="vx-card" style="padding: 1.5rem; margin-top: 20px;">
                    <label class="vs-input--label">Формирование отчета в формате CSV. Выберите дату и время</label>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <label class="vs-input--label">от:</label>
                            <flat-pickr class="w-full" 
                                name="cstart" 
                                v-model="csvStart" 
                                :config="configcsvDateTimePicker" />
                        </div>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <label class="vs-input--label">до:</label>
                            <flat-pickr class="w-full" 
                                name="cend" 
                                v-model="csvEnd" 
                                :config="configcsvDateTimePicker" />
                        </div>
                    </div>
                    <div class="flex">
                        <vs-button class="" style="width: 100%;" @click="downloadCSV()">Скачать CSV</vs-button>
                    </div>
                </div>
                
            </div>
        </div>
    </vx-card>
</template>

<script>
// TODO Cделать чтоб модель таймпикеров была в виде Date объекта, а не строкой
// TODO Исправить как задаются все дефолтные значения - defaultEventObj.time_tariff - багует иногда
// TODO Может задавать дефолтную группу не в бэкенде, а здесь

import qs from 'qs';
import _debounce from 'lodash/debounce';

import ruLocale from '@fullcalendar/core/locales/ru';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {Russian as RussianLocale} from 'flatpickr/dist/l10n/ru.js';

const encoding = require('encoding');

export default {
    components: {
        flatPickr
    },
    data() {
        return {
            csvStart: '',
            csvEnd: '',
            cstart: undefined,
            cend: undefined,
            snapDuration: { minutes: 5 },
            calendarPlugins: [ dayGridPlugin, timeGridPlugin, interactionPlugin ],
            locale: ruLocale,

            configDateTimePicker: {
                enableTime: true,
                dateFormat: 'd/m/Y H:i',
                locale: RussianLocale,
                allowInput: true,
                disableMobile: true
            },
            configTimePicker: {
                enableTime: true,
                noCalendar: true,
                locale: RussianLocale,
                allowInput: true,
                disableMobile: true
            },
            configcsvDateTimePicker: {
                enableTime: true,
                dateFormat: 'd/m/Y H:i',
                locale: RussianLocale,
                allowInput: true,
                disableMobile: false
            },
        }
    },
    methods: {
        downloadCSV() {
            let data = {
                'data': {
                    'csvstart': this.csvStart,
                    'csvend': this.csvEnd
                }
            }
            this.$http({
                method: 'post',
                data: qs.stringify(data),
                url: '/udata/emarket/get_selected_ideal_report/.json',
                withCredentials: true
            }).then((response) => {
                try {
                    const csv = encoding.convert(response.data.result, 'WINDOWS-1251', 'UTF-8');
                    let filename = ('отчет_по_тарифам_' + this.csvStart + '_-_' + this.csvEnd + '__' + this.$helpers.formatDateTime(this.$store.state.now) + '.csv').replace(/ /g, '_');
                    this.$helpers.downloadCSV(csv, filename);
                } catch (err) {
                    this.$vs.notify({
                        color:'danger',
                        title:'Ошибка',
                        text:err
                    });
                }
                if (response.data.error) {
                    this.$vs.notify({
                        title:'Ошибка при формировании CSV',
                        text: response.data.error,
                        color:'warning',
                        iconPack: 'feather',
                        icon:'icon-alert-circle'
                    })          
                } else {
                    this.editedEvent.id = null;
                }
            })
            .catch((error) => {
                this.$refs.calendar.getApi().refetchEvents();
                this.$vs.notify({
                    title:'Ошибка при формировании CSV',
                    text: error,
                    color:'danger',
                    iconPack: 'feather',
                    icon:'icon-alert-circle'
                })
            });
        },
        selectDateRange(selectionInfo) {
            this.editedEvent.start = selectionInfo.start;
            this.editedEvent.end = selectionInfo.end;
        }
    }
}
</script>

<style lang='scss'>
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/timegrid/main.css';
</style>
